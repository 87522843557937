import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import { CaptchaInfo } from "components/CaptchaManager/CaptchaManager"
import { useEffect, useMemo, useState } from "react"
import { checkUserIsNotConfirmed } from "utils/requests/checkUserIsNotConfirmed"
import { getCognitoUser } from "utils/requests/getCognitoUser"

export interface CognitoSelectors {
  user: Map<string, string> | null
  session: AmazonCognitoIdentity.CognitoUserSession | null
}

export interface CognitoActions {
  handleSignIn: (
    email: string,
    password: string,
    captchaInfo: CaptchaInfo
  ) => Promise<any>
  handleSignUp: (
    email: string,
    password: string,
    captchaInfo: CaptchaInfo
  ) => Promise<any>
  handleSignOut: () => void
  handleForgotPassword: (email: string) => Promise<any>
  handleNewPasswordCreation: (
    email: string,
    verificationCode: string,
    newPassword: string
  ) => Promise<any>
  handleCodeVerificationRequest: (email: string) => Promise<any>
  handleCodeVerification: (
    userId: string,
    verificationCode: string
  ) => Promise<any>
  getCurrentUser: (args?: { force: boolean }) => Promise<any>
  updateUserAttributes: (attributes: Record<string, any>) => Promise<any>
}

export interface CognitoHook {
  selectors: CognitoSelectors
  actions: CognitoActions
}

export const cookieDomain = process.env.NEXT_PUBLIC_VERCEL_ENV
  ? ".voxies.io"
  : "localhost" // local development

export const cookieStorageParams = {
  domain: cookieDomain,
  path: "/",
  secure: !!process.env.NEXT_PUBLIC_VERCEL_ENV,
  expires: 365,
}

export const cookieStorage = new AmazonCognitoIdentity.CookieStorage(
  cookieStorageParams
)

export const poolData = {
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID as string,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID as string,
  Storage: cookieStorage,
}

export type ForgotPasswordResponseType = "password-reset" | "email-confirmation"

export interface ValidationDataProps {
  turnstileToken: string
  sourceIp: string
  skipKey?: string
}

export function useCognito(): CognitoHook {
  const [user, setUser] = useState<Map<string, string> | null>(null)
  const [session, setSession] =
    useState<AmazonCognitoIdentity.CognitoUserSession | null>(null)

  const userPool = useMemo(
    () => new AmazonCognitoIdentity.CognitoUserPool(poolData),
    []
  )

  const handleSignIn = (
    email: string,
    password: string,
    captchaInfo: CaptchaInfo
  ) => {
    return new Promise((resolve, reject) => {
      const validationData: ValidationDataProps = {
        turnstileToken: captchaInfo.token,
        sourceIp: captchaInfo.ipAddress,
      }

      if (process.env.NEXT_PUBLIC_ENV === "test") {
        validationData.skipKey = process.env.NEXT_PUBLIC_SKIP_KEY as string
      }

      const authenticationData = {
        Username: email,
        Password: password,
        ValidationData: validationData,
        ClientMetadata: {
          sourceIp: captchaInfo.ipAddress,
          turnstileToken: captchaInfo.token,
          environment: process.env.NEXT_PUBLIC_ENV as string,
        },
      }

      const authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)

      const userData = {
        Username: email,
        Pool: userPool,
        Storage: cookieStorage,
      }
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async () => resolve(await getCurrentUser()),
        onFailure: async err => {
          if (err.message === "User is not confirmed.") {
            await handleCodeVerificationRequest(email)
            resolve("not confirmed")
          } else {
            console.error("Authentication failed", err)
            reject(err)
          }
        },
      })
    })
  }

  const handleSignUp = (
    email: string,
    password: string,
    captchaInfo: CaptchaInfo
  ) => {
    return new Promise((resolve, reject) => {
      const turnstileToken = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "turnstileToken",
        Value: captchaInfo.token,
      })

      const validationData = [turnstileToken]

      if (process.env.NEXT_PUBLIC_ENV === "test") {
        validationData.push(
          new AmazonCognitoIdentity.CognitoUserAttribute({
            Name: "skipKey",
            Value: process.env.NEXT_PUBLIC_SKIP_KEY as string,
          })
        )
      }

      userPool.signUp(
        email,
        password,
        [],
        validationData,
        (err, result) => {
          if (err) {
            console.error("Sign up failed", err)
            reject(err)
          } else {
            resolve(result)
          }
        },
        {
          sourceIp: captchaInfo.ipAddress,
          environment: process.env.NEXT_PUBLIC_ENV as string,
        }
      )
    })
  }

  const handleForgotPassword = async (username: string) => {
    const userData = {
      Username: username,
      Pool: userPool,
      Storage: cookieStorage,
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    try {
      const isNotConfirmed = await checkUserIsNotConfirmed(username)

      if (isNotConfirmed) {
        await handleCodeVerificationRequest(username)
        return "email-confirmation"
      }

      return new Promise<ForgotPasswordResponseType>((resolve, reject) => {
        cognitoUser.forgotPassword(
          {
            onSuccess: () => resolve("password-reset"),
            onFailure: reject,
          },
          {
            environment: process.env.NEXT_PUBLIC_ENV as string,
          }
        )
      })
    } catch (error) {
      throw error
    }
  }

  const handleNewPasswordCreation = (
    email: string,
    verificationCode: string,
    newPassword: string
  ) => {
    const userData = {
      Username: email,
      Pool: userPool,
      Storage: cookieStorage,
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: resolve,
        onFailure: reject,
      })
    })
  }

  const handleCodeVerificationRequest = (email: string) => {
    const userData = {
      Username: email,
      Pool: userPool,
      Storage: cookieStorage,
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode(
        (error, result) => {
          if (error) {
            console.error("Code verification request failed", error)
            reject(error)
          } else {
            resolve(result)
          }
        },
        {
          environment: process.env.NEXT_PUBLIC_ENV as string,
        }
      )
    })
  }

  const handleCodeVerification = async (
    userId: string,
    verificationCode: string
  ) => {
    const user = await getCognitoUser(userId)
    const email = user?.attributes.get("email")

    return new Promise((resolve, reject) => {
      if (!email) {
        reject(new Error("User not found"))
      }

      const userData = {
        Username: email as string,
        Pool: userPool,
        Storage: cookieStorage,
      }
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
        if (err) {
          console.error("Code verification failed", err)
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  const getCurrentUser = ({ force } = { force: false }) => {
    return new Promise<{
      user: Map<string, string> | null
      session: AmazonCognitoIdentity.CognitoUserSession | null
    }>((resolve, reject) => {
      if (user !== null && !force) {
        resolve({ user, session })
      }

      const cognitoUser = userPool.getCurrentUser()
      if (!cognitoUser) {
        return resolve({ user: null, session: null })
      }

      return cognitoUser.getSession((error, session) => {
        if (error) {
          console.error(error.message || JSON.stringify(error))
          return
        }

        if (!session.isValid()) {
          console.error("Session is invalid")
          return
        }

        setSession(session)

        // NOTE: getSession must be called to authenticate user before calling getUserAttributes
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err || !attributes) {
            reject(err)
            return
          }

          const attributesForMap = attributes.map(item => [
            item.Name,
            item.Value,
          ]) as any

          setUser(new Map(attributesForMap))
          resolve({
            session: session as AmazonCognitoIdentity.CognitoUserSession,
            user: new Map(attributesForMap) as Map<string, string>,
          })
        })
      })
    })
  }

  const updateUserAttributes = (attributes: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser()
      if (!cognitoUser) {
        reject(new Error("User not found"))
        return
      }

      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(new Error(error.message || JSON.stringify(error)))
          return
        }

        if (!session.isValid()) {
          reject(new Error("Session is invalid"))
          return
        }

        const attributeList: AmazonCognitoIdentity.CognitoUserAttribute[] = []

        for (const key in attributes) {
          attributeList.push(
            new AmazonCognitoIdentity.CognitoUserAttribute({
              Name: key,
              Value: attributes[key],
            })
          )
        }

        cognitoUser.updateAttributes(attributeList, (err, result) => {
          if (err) {
            reject(err)
          } else {
            resolve(result)
          }
        })
      })
    })
  }

  const handleSignOut = () => {
    setUser(null)
    setSession(null)

    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser != null) {
      cognitoUser.signOut()
    }
  }

  useEffect(() => {
    if (!user && !session) {
      getCurrentUser()
    }
  }, [user, session])

  return {
    selectors: { user, session },
    actions: {
      handleSignIn,
      handleSignUp,
      handleSignOut,
      handleForgotPassword,
      handleNewPasswordCreation,
      handleCodeVerificationRequest,
      handleCodeVerification,
      getCurrentUser,
      updateUserAttributes,
    },
  }
}
