import classNames from "classnames"
import { ReactNode } from "react"

export type ButtonProps = {
  disabled?: boolean
  className?: string
  label?: string | ReactNode
  icon?: (props: IconType) => JSX.Element
  variant?: "primary" | "success" | "secondary"
  type?: "button" | "submit" | "reset" | undefined
  onClick?: () => void
  isLoading?: boolean
}

export const commonClass =
  "text-base inline-flex justify-center items-center gap-3 py-3 px-5 rounded-full transition duration-200 ease-in-out group"
export const primaryClass =
  "bg-blue-500 text-white hover:bg-blue-700 disabled:bg-base-200 disabled:text-base-300"
export const successClass = "bg-success text-white hover:bg-success-700"
export const secondaryClass =
  "border border-gray-300 text-base-content disabled:border-base-200 disabled:text-base-300  hover:border-gray-500"

const secondaryIcon =
  "fill-base-content group-hover:fill-primary-focus group-disabled:fill-base-300"

export const Button = ({
  label,
  disabled,
  onClick,
  className,
  type = "button",
  variant = "primary",
  icon: Icon,
  isLoading,
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames(commonClass, {
        [`${primaryClass}`]: variant === "primary",
        [`${successClass}`]: variant === "success",
        [`${secondaryClass}`]: variant === "secondary",
        [`${className}`]: !!className,
        loadingButton: isLoading,
      })}
      onClick={onClick}
    >
      {Icon && (
        <Icon
          className={classNames("h-4 w-4", {
            [`${secondaryIcon}`]: variant === "secondary",
          })}
          color="currentFill"
        />
      )}
      <span className="empty:hidden">{label}</span>
    </button>
  )
}
